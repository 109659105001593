import axiosInstance from "./AxiosInstance";

export function getBanners() {
  let data = {
    title: "paul walker new",
    imageUrl:
      "https://www.nme.com/news/film/paul-walkers-fast-furious-car-sells-for-555000-at-auction-2975453.jpg",
    status: "active",
    deleted: 0,
  };
  return axiosInstance.get(`/admin/banner`, data);
}

export function createBanner(bannerData) {
  return axiosInstance.post(`/admin/banner`, bannerData);
}

export function updateBanner(bannerData, bannerId) {
  return axiosInstance.put(`/admin/banner/${bannerId}`, bannerData);
}

export function deleteBanner(banner) {
  return axiosInstance.put(`/admin/banner/${banner._id}`, banner);
}

export function formatBanners(bannerData) {
  let banners = [];
  for (let key in bannerData) {
    banners.push({ ...bannerData[key], id: key });
  }

  return banners;
}
