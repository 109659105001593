import {
  CONFIRMED_CREATE_BANNER_ACTION,
  CONFIRMED_DELETE_BANNER_ACTION,
  CONFIRMED_EDIT_BANNER_ACTION,
  CONFIRMED_GET_BANNERS,
  CREATE_BANNER_ACTION,
} from "../actions/BannerTypes.js";

const initialState = {
  banners: [],
};

export default function BannersReducer(state = initialState, actions) {
  if (actions.type === CREATE_BANNER_ACTION) {
    const banner = {
      id: Math.random(),
      title: "Banner Title 2asdasd",
      description: "Sample Description 2asdasdas",
    };

    const banners = [...state.banners];
    banners.push(banner);
    return {
      ...state,
      banners,
    };
  }

  if (actions.type === CONFIRMED_DELETE_BANNER_ACTION) {
    const banners = [...state.banners];
    const bannerIndex = banners.findIndex(
      (banner) => banner.id === actions.payload
    );

    banners.splice(bannerIndex, 1);

    return {
      ...state,
      banners,
    };
  }

  if (actions.type === CONFIRMED_EDIT_BANNER_ACTION) {
    const banners = [...state.banners];
    const bannerIndex = banners.findIndex(
      (banner) => banner.id === actions.payload.id
    );

    banners[bannerIndex] = actions.payload;
    return {
      ...state,
      banners,
    };
  }

  if (actions.type === CONFIRMED_CREATE_BANNER_ACTION) {
    const banners = [...state.banners];
    banners.push(actions.payload);

    return {
      ...state,
      banners,
    };
  }

  if (actions.type === CONFIRMED_GET_BANNERS) {
    return {
      ...state,
      banners: actions.payload,
    };
  }
  return state;
}
