import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { IMAGES } from "../../constant/theme";
import EmployeeOffcanvas from "../../constant/EmployeeOffcanvas";
import MainPagetitle from "../../layouts/MainPagetitle";
import { Button } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import {
  deleteServiceAction,
  getServicesAction,
} from "../../../store/actions/ServicesActions";

const Service = (props) => {
  const [tableData, setTableData] = useState([]);
  const [activePage, setActivePage] = useState(0);
  const sort = 10;
  const employe = useRef(null);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [selectedService, setSelectedService] = useState(undefined);

  useEffect(() => {
    // dispatch(loadingToggleAction(true));
    dispatch(getServicesAction());
  }, []);

  useEffect(() => {
    console.log("bannerData ======", props.servicesData);
    if (props.servicesData && props.servicesData.length > 0) {
      setTableData(props.servicesData);
    }
  }, [props.servicesData]);

  const handleDelete = (index) => {
    if (window.confirm("Are you sure you want to delete this banner?")) {
      const actualIndex = startIndex + index; // Calculate the correct index

      const newData = tableData.filter((_, i) => i !== actualIndex);
      let tobeDeleted = tableData[actualIndex];
      tobeDeleted.deleted = 1;

      dispatch(deleteServiceAction(tobeDeleted, tobeDeleted._id));
      setTableData(newData);
    }
  };

  const handleEdit = (index) => {
    console.log("Edit item at index:", index);
    const actualIndex = startIndex + index; // Calculate the correct index
    setSelectedService(tableData[actualIndex]);
    navigate("/addService", {
      state: { selectedService: tableData[actualIndex] },
    });
  };

  const handleAddEmployee = () => {
    // if (employe.current) {
    //   employe.current.showEmployeeOffcanvasModal();
    // }
    navigate("/addService");
  };

  const pagination = Array(Math.ceil(tableData?.length / sort))
    .fill()
    .map((_, i) => i + 1);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const startIndex = activePage * sort;
  const endIndex = startIndex + sort;
  const paginatedData = tableData.slice(startIndex, endIndex);

  return (
    <>
      <MainPagetitle
        mainTitle="Service"
        pageTitle="Service"
        parentTitle="Home"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-14 col-lg-16">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">Service</h4>
                    <div className="d-flex align-items-center">
                      <CSVLink
                        headers={[
                          { label: "Brand Name", key: "brandName" },
                          { label: "Client Name", key: "clientName" },
                          { label: "Headline", key: "headline" },
                          { label: "Status", key: "status" },
                        ]}
                        data={tableData}
                        filename="services.csv"
                        className="btn btn-primary light btn-sm me-1"
                      >
                        <i className="fa-solid fa-file-excel" /> Export Report
                      </CSVLink>
                      <Button
                        to="#"
                        className="btn btn-primary btn-sm ms-1"
                        onClick={handleAddEmployee}
                      >
                        + Add Service
                      </Button>
                    </div>
                  </div>
                  <div
                    id="service-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="service-tblwrapper"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0"
                    >
                      <thead>
                        <tr>
                          <th>Image</th>
                          <th>Brand Name</th>
                          <th>Client Name</th>
                          <th>Headline</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <img
                                src={item.image}
                                className="avatar avatar-md"
                                alt={item.brandName}
                              />
                            </td>
                            <td>{item.brandName}</td>
                            <td>{item.clientName}</td>
                            <td>{item.headline}</td>
                            <td>
                              <span
                                className={`badge light border-0 ${
                                  item.status == "1"
                                    ? "badge-success"
                                    : "badge-danger"
                                }`}
                              >
                                {item.status == "1" ? "Active" : "Inactive"}
                              </span>
                            </td>
                            <td>
                              <Button
                                to="#"
                                className="btn btn-primary btn-sm me-2"
                                onClick={() => handleEdit(index)}
                              >
                                <i className="fa-solid fa-pencil-alt" />
                              </Button>
                              <button
                                className="btn btn-danger btn-sm"
                                onClick={() => handleDelete(index)}
                              >
                                <i className="fa-solid fa-trash" />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div className="dataTables_info">
                      Showing {startIndex + 1} to{" "}
                      {tableData?.length > endIndex
                        ? endIndex
                        : tableData?.length}{" "}
                      of {tableData?.length} entries
                    </div>
                    <div className="dataTables_paginate paging_simple_numbers">
                      <Link
                        className="paginate_button previous"
                        to="#"
                        onClick={() =>
                          activePage > 0 && handlePageChange(activePage - 1)
                        }
                      >
                        <i className="fa-solid fa-angle-left" />
                      </Link>
                      <span>
                        {pagination.map((number, i) => (
                          <Link
                            key={i}
                            to="#"
                            className={`paginate_button ${
                              activePage === i ? "current" : ""
                            }`}
                            onClick={() => handlePageChange(i)}
                          >
                            {number}
                          </Link>
                        ))}
                      </span>
                      <Link
                        className="paginate_button next"
                        to="#"
                        onClick={() =>
                          activePage < pagination?.length - 1 &&
                          handlePageChange(activePage + 1)
                        }
                      >
                        <i className="fa-solid fa-angle-right" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <EmployeeOffcanvas ref={employe} Title="Add Service" /> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.banners.errorMessage,
    successMessage: state.banners.successMessage,
    servicesData: state.services.services,
    showLoading: state.banners.showLoading,
  };
};
export default connect(mapStateToProps)(Service);
