import axios from "axios";
import { store } from "../store/store";
import { updateToken } from "../store/actions/AuthActions";
import swal from "sweetalert2";
import { navigateToLogin } from "./navigate";

export const baseURL = `https://api-staging.simpli5.in`;

const axiosInstance = axios.create({
  baseURL: baseURL,
  // baseURL: `http://localhost:3000`,
  // baseURL: "https://4c40-115-242-141-38.ngrok-free.app",
});

axiosInstance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    console.log("State ======", state);
    console.log("config ======", config);

    // Check if auth and authKey exist
    if (state.auth && state.auth.authKey) {
      const token = state.auth.authKey;

      // Add the token to the request headers
      config.headers = {
        ...config.headers,
        authKey: `${token}`, // Or any custom header name if necessary
      };
    }

    return config;
  },
  (error) => {
    console.log("failue =====", error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    const newToken = response.headers["authkey"] || response.headers["authkey"];

    if (newToken) {
      store.dispatch(updateToken(newToken)); // Dispatch the action to update the token
    }
    console.log("updated token =====", newToken);

    return response;
  },
  (error) => {
    if (error.status === 401) {
      alert("OOPS!! Session expired", "try to login again");

      navigateToLogin();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
