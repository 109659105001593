import {
  createBanner,
  formatBanners,
  getBanners,
  deleteBanner,
  updateBanner,
} from "../../services/BannersService";
import {
  CONFIRMED_CREATE_BANNER_ACTION,
  CONFIRMED_DELETE_BANNER_ACTION,
  CONFIRMED_EDIT_BANNER_ACTION,
  CONFIRMED_GET_BANNERS,
} from "./BannerTypes";
import swal from "sweetalert";

export function deleteBannerAction(banner, history) {
  return (dispatch, getState) => {
    deleteBanner(banner).then((response) => {
      dispatch(confirmedDeleteBannerAction(banner));
      history.push("/bannerpage");
    });
  };
}

export function confirmedDeleteBannerAction(banner) {
  return {
    type: CONFIRMED_DELETE_BANNER_ACTION,
    payload: banner,
  };
}

export function createBannerAction(bannerData, history) {
  return (dispatch, getState) => {
    createBanner(bannerData).then((response) => {
      if (response) {
        const singleBanner = {
          bannerData: bannerData,
          imageUrl: response.data.imageUrl,
          title: response.data.title,
        };
        dispatch(confirmedCreateBannerAction(singleBanner));
        history.push("/bannerpage");
      } else {
        swal("Oops", "Failed to create banner", "error");
      }
    });
  };
}

export function getBannersAction() {
  return (dispatch, getState) => {
    getBanners().then((response) => {
      console.log("get banner responce=====", response);
      if (response) {
        let banners = formatBanners(response.data);
        dispatch(confirmedGetBannersAction(banners));
      } else {
        swal("Oops", "Failed to get banners", "error");
      }
    });
  };
}

export function confirmedCreateBannerAction(singleBanner) {
  return {
    type: CONFIRMED_CREATE_BANNER_ACTION,
    payload: singleBanner,
  };
}

export function confirmedGetBannersAction(banners) {
  return {
    type: CONFIRMED_GET_BANNERS,
    payload: banners,
  };
}

export function confirmedUpdateBannerAction(banner) {
  return {
    type: CONFIRMED_EDIT_BANNER_ACTION,
    payload: banner,
  };
}

export function updateBannerAction(banner, bannerId, history) {
  return (dispatch, getState) => {
    updateBanner(banner, bannerId).then((reponse) => {
      if (reponse) {
        dispatch(confirmedUpdateBannerAction(banner));
        history.push("/bannerpage");
      } else {
        swal("Oops", "Failed to update banners", "error");
      }
    });
  };
}
