import { useNavigate } from "react-router-dom";
import { store } from "../store/store";
import { updateToken } from "../store/actions/AuthActions";

let navigate;

export const setNavigate = (nav) => {
  navigate = nav;
};

export const navigateToLogin = () => {
  if (navigate) {
    store.dispatch(updateToken(undefined));
    navigate("/login");
  } else {
    console.error("Navigation method not set");
  }
};
