import {
  CONFIRMED_CREATE_SERVICES_ACTION,
  CONFIRMED_DELETE_SERVICES_ACTION,
  CONFIRMED_EDIT_SERVICES_ACTION,
  CONFIRMED_GET_SERVICES,
  CREATE_SERVICES_ACTION,
} from "../actions/ServicesTypes.js";

const initialState = {
  services: [],
};

export default function ServicesReducers(state = initialState, actions) {
  if (actions.type === CREATE_SERVICES_ACTION) {
    const service = {
      id: Math.random(),
      title: "Service Title 2asdasd",
      description: "Sample Description 2asdasdas",
    };

    const services = [...state.services];
    services.push(service);
    return {
      ...state,
      services,
    };
  }

  if (actions.type === CONFIRMED_DELETE_SERVICES_ACTION) {
    const services = [...state.services];
    const serviceIndex = services.findIndex(
      (service) => service.id === actions.payload
    );

    services.splice(serviceIndex, 1);

    return {
      ...state,
      services,
    };
  }

  if (actions.type === CONFIRMED_EDIT_SERVICES_ACTION) {
    const services = [...state.services];
    const serviceIndex = services.findIndex(
      (service) => service.id === actions.payload.id
    );

    services[serviceIndex] = actions.payload;
    return {
      ...state,
      services,
    };
  }

  if (actions.type === CONFIRMED_CREATE_SERVICES_ACTION) {
    const services = [...state.services];
    services.push(actions.payload);

    return {
      ...state,
      services,
    };
  }

  if (actions.type === CONFIRMED_GET_SERVICES) {
    return {
      ...state,
      services: actions.payload,
    };
  }
  return state;
}
