import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Offcanvas } from "react-bootstrap";
import Select from "react-select";

const optionsButtonURLType = [
  { value: "External", label: "External" },
  { value: "Internal", label: "Internal" },
];

const optionsTextViewURLType = [
  { value: "External", label: "External" },
  { value: "Internal", label: "Internal" },
];

const optionsStatus = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];

const EmployeeOffcanvas = forwardRef((props, ref) => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [formData, setFormData] = useState({
    Id: "",
    identifier: "",
    brandName: "",
    headline: "",
    brandImage: "",
    bannerImage: "",
    buttonText: "",
    buttonURL: "",
    buttonURLType: optionsButtonURLType[0],
    textViewText: "",
    textViewURL: "",
    textViewURLType: optionsTextViewURLType[0],
    status: optionsStatus[0],
  });

  useImperativeHandle(ref, () => ({
    showEmployeeOffcanvasModal() {
      setShowOffcanvas(true);
    },
  }));

  const handleInputChange = (e, field) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  const handleSelectChange = (selectedOption, field) => {
    setFormData({ ...formData, [field]: selectedOption });
  };

  const handleImageUpload = (e, field) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, [field]: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data:", formData);
    // Add your form submission logic here
  };

  return (
    <Offcanvas
      show={showOffcanvas}
      onHide={() => setShowOffcanvas(false)}
      className="offcanvas-end custom-offcanvas"
      placement="end"
    >
      <div className="offcanvas-header">
        <h4 className="card-title">{props.Title || "Add Service"}</h4>
        <button
          type="button"
          className="btn-close"
          onClick={() => setShowOffcanvas(false)}
        ></button>
      </div>
      <div className="offcanvas-body">
        <form onSubmit={handleSubmit}>
          <div className="card-body">
            <div className="row">
              <div className="col-xl-6 mb-3">
                <label className="form-label">
                  ID <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.Id}
                  onChange={(e) => handleInputChange(e, "Id")}
                  required
                />
              </div>
              <div className="col-xl-6 mb-3">
                <label className="form-label">
                  Identifier <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.identifier}
                  onChange={(e) => handleInputChange(e, "identifier")}
                  required
                />
              </div>
              <div className="col-xl-6 mb-3">
                <label className="form-label">
                  Brand Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.brandName}
                  onChange={(e) => handleInputChange(e, "brandName")}
                  required
                />
              </div>
              <div className="col-xl-6 mb-3">
                <label className="form-label">
                  Headline <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.headline}
                  onChange={(e) => handleInputChange(e, "headline")}
                  required
                />
              </div>
              <div className="col-xl-6 mb-3">
                <label className="form-label">
                  Button URL Type <span className="text-danger">*</span>
                </label>
                <Select
                  options={optionsButtonURLType}
                  value={formData.buttonURLType}
                  onChange={(option) =>
                    handleSelectChange(option, "buttonURLType")
                  }
                  className="custom-react-select"
                  isSearchable={false}
                  required
                />
              </div>
              <div className="col-xl-6 mb-3">
                <label className="form-label">
                  Status <span className="text-danger">*</span>
                </label>
                <Select
                  options={optionsStatus}
                  value={formData.status}
                  onChange={(option) => handleSelectChange(option, "status")}
                  className="custom-react-select"
                  isSearchable={false}
                  required
                />
              </div>
              <div className="col-xl-6 mb-3">
                <label className="form-label">
                  Button Text <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.buttonText}
                  onChange={(e) => handleInputChange(e, "buttonText")}
                  required
                />
              </div>
              <div className="col-xl-6 mb-3">
                <label className="form-label">
                  TextView Text <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.textViewText}
                  onChange={(e) => handleInputChange(e, "textViewText")}
                  required
                />
              </div>
              <div className="col-xl-6 mb-3">
                <label className="form-label">
                  TextView URL Type <span className="text-danger">*</span>
                </label>
                <Select
                  options={optionsTextViewURLType}
                  value={formData.textViewURLType}
                  onChange={(option) =>
                    handleSelectChange(option, "textViewURLType")
                  }
                  className="custom-react-select"
                  isSearchable={false}
                  required
                />
              </div>
              <div className="col-xl-6 mb-3">
                <label className="form-label">Brand Image</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => handleImageUpload(e, "brandImage")}
                />
                {formData.brandImage && (
                  <img
                    src={formData.brandImage}
                    alt="Brand"
                    className="img-thumbnail mt-2"
                    style={{ width: "100px", height: "auto" }}
                  />
                )}
              </div>
              <div className="col-xl-6 mb-3">
                <label className="form-label">Banner Image</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => handleImageUpload(e, "bannerImage")}
                />
                {formData.bannerImage && (
                  <img
                    src={formData.bannerImage}
                    alt="Banner"
                    className="img-thumbnail mt-2"
                    style={{ width: "100px", height: "auto" }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="card-footer d-flex justify-content-center">
            <button type="submit" className="btn btn-primary me-2">
              Submit
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowOffcanvas(false)}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Offcanvas>
  );
});

export default EmployeeOffcanvas;
