import axiosInstance from "./AxiosInstance";

export function getServices() {
  let data = {
    title: "paul walker new",
    bannerUrl:
      "https://www.nme.com/news/film/paul-walkers-fast-furious-car-sells-for-555000-at-auction-2975453.jpg",
    status: "active",
    image: "",
    deleted: 0,
  };
  return axiosInstance.get(`/admin/s5-service`, data);
}

export function createService(serviceData) {
  return axiosInstance.post(`/admin/s5-service`, serviceData);
}

export function updateService(serviceData, serviceId) {
  return axiosInstance.put(`/admin/s5-service/${serviceId}`, serviceData);
}

export function deleteService(service) {
  return axiosInstance.put(`/admin/s5-service/${service._id}`, service);
}

export function formatServices(serviceData) {
  let services = [];
  for (let key in serviceData) {
    services.push({ ...serviceData[key], id: key });
  }

  return services;
}
