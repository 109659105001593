export const CREATE_SERVICES_ACTION = "[SERVICES Action] Create SERVICES";
export const CONFIRMED_CREATE_SERVICES_ACTION =
  "[SERVICES Action] Confirmed Create SERVICES";
export const GET_SERVICES = "[SERVICES Action] Get SERVICESs";
export const CONFIRMED_GET_SERVICES =
  "[SERVICES Action] Confirmed Get SERVICESs";
export const EDIT_SERVICES_ACTION = "[SERVICES Action] Edit SERVICES";
export const CONFIRMED_EDIT_SERVICES_ACTION =
  "[SERVICES Action] Confirmed Edit SERVICES";
export const CONFIRMED_DELETE_SERVICES_ACTION =
  "[SERVICES Action] Confirmed Delete SERVICES";
