import {
  formatError,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
  // signUp,
} from "../../services/AuthService";
import {
  UPDATE_TOKEN,
  LOGOUT_ACTION,
  LOGIN_CONFIRMED_ACTION,
  LOADING_TOGGLE_ACTION,
  LOGIN_FAILED_ACTION,
  store,
} from "../store";

// export function signupAction(email, password, navigate) {
//     return (dispatch) => {
//         signUp(email, password)
//         .then((response) => {
//             saveTokenInLocalStorage(response.data);
//             runLogoutTimer(
//                 dispatch,
//                 response.data.expiresIn * 1000,
//                 //history,
//             );
//             dispatch(confirmedSignupAction(response.data));
//             navigate('/dashboard');
// 			//history.push('/dashboard');
//         })
//         .catch((error) => {
//             const errorMessage = formatError(error.response.data);
//             dispatch(signupFailedAction(errorMessage));
//         });
//     };
// }

export function updateToken(token) {
  return (dispatch) => {
    dispatch(updateTokenAction(token));
  };
}

export const updateTokenAction = (token) => {
  return {
    type: UPDATE_TOKEN,
    payload: token,
  };
};

export function Logout(navigate) {
  localStorage.removeItem("userDetails");
  store.dispatch(updateToken(undefined)); // Dispatch the action to update the token
  navigate("/login");
  //history.push('/login');

  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(email, password, navigate) {
  return (dispatch) => {
    login(email, password)
      .then((response) => {
        saveTokenInLocalStorage(response.data.message);
        // runLogoutTimer(dispatch, 1000 * 1000, navigate);
        dispatch(loginConfirmedAction(response.data.message));
        navigate("/dashboard");
      })
      .catch((error) => {
        console.log("error =====", error);
        console.log("error =====", error.message);

        const errorMessage = formatError(error.message);
        dispatch(loginFailedAction(error.message));
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

// export function confirmedSignupAction(payload) {
//     return {
//         type: SIGNUP_CONFIRMED_ACTION,
//         payload,
//     };
// }

// export function signupFailedAction(message) {
//     return {
//         type: SIGNUP_FAILED_ACTION,
//         payload: message,
//     };
// }

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
