import {
  createService,
  formatServices,
  getServices,
  deleteService,
  updateService,
} from "../../services/ServicesService";
import {
  CONFIRMED_CREATE_SERVICES_ACTION,
  CONFIRMED_DELETE_SERVICES_ACTION,
  CONFIRMED_EDIT_SERVICES_ACTION,
  CONFIRMED_GET_SERVICES,
} from "./ServicesTypes";
import swal from "sweetalert";

export function deleteServiceAction(service, history) {
  return (dispatch, getState) => {
    deleteService(service).then((response) => {
      dispatch(confirmedDeleteServiceAction(service));
      history.push("/servicepage");
    });
  };
}

export function confirmedDeleteServiceAction(service) {
  return {
    type: CONFIRMED_DELETE_SERVICES_ACTION,
    payload: service,
  };
}

export function createServiceAction(serviceData, history) {
  return (dispatch, getState) => {
    createService(serviceData).then((response) => {
      if (response) {
        const singleService = {
          serviceData: serviceData,
          imageUrl: response.data.imageUrl,
          title: response.data.title,
        };
        dispatch(confirmedCreateServiceAction(singleService));
        history.push("/servicepage");
      } else {
        swal("Oops", "Failed to create service", "error");
      }
    });
  };
}

export function getServicesAction() {
  return (dispatch, getState) => {
    getServices().then((response) => {
      if (response) {
        let services = formatServices(response.data);
        dispatch(confirmedGetServicesAction(services));
      } else {
        swal("Oops", "Failed to get service", "error");
      }
    });
  };
}

export function confirmedCreateServiceAction(singleService) {
  return {
    type: CONFIRMED_CREATE_SERVICES_ACTION,
    payload: singleService,
  };
}

export function confirmedGetServicesAction(services) {
  return {
    type: CONFIRMED_GET_SERVICES,
    payload: services,
  };
}

export function confirmedUpdateServiceAction(service) {
  return {
    type: CONFIRMED_EDIT_SERVICES_ACTION,
    payload: service,
  };
}

export function updateServiceAction(service, serviceId, history) {
  return (dispatch, getState) => {
    updateService(service, serviceId).then((reponse) => {
      if (reponse) {
        dispatch(confirmedUpdateServiceAction(service));
        history.push("/servicepage");
      } else {
        swal("Oops", "Failed to update service", "error");
      }
    });
  };
}
