import React from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

// Define options for select fields
const urlTypeOptions = [
  { value: "External", label: "External" },
  { value: "Internal", label: "Internal" },
];

const statusOptions = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];

const EditProfile = () => {
  return (
    <div className="col-xl-9 col-lg-8">
      <div className="card profile-card card-bx m-b30">
        <div className="card-header">
          <h6 className="title">Account setup</h6>
        </div>
        <form className="profile-form">
          <div className="card-body">
            <div className="row">
              {/* Id (text) */}
              <div className="col-sm-6 m-b30">
                <label className="form-label">Id</label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue=""
                  placeholder="Enter ID"
                />
              </div>

              {/* Identifier (text) */}
              <div className="col-sm-6 m-b30">
                <label className="form-label">Identifier</label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue=""
                  placeholder="Enter identifier"
                />
              </div>

              {/* Brand Name (text) */}
              <div className="col-sm-6 m-b30">
                <label className="form-label">Brand Name</label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue=""
                  placeholder="Enter brand name"
                />
              </div>

              {/* Headline (text) */}
              <div className="col-sm-6 m-b30">
                <label className="form-label">Headline</label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue=""
                  placeholder="Enter headline"
                />
              </div>

              {/* Brand Image (file) */}
              <div className="col-sm-6 m-b30">
                <label className="form-label">Brand Image</label>
                <input type="file" className="form-control-file" />
              </div>

              {/* Banner Image (file) */}
              <div className="col-sm-6 m-b30">
                <label className="form-label">Banner Image</label>
                <input type="file" className="form-control-file" />
              </div>

              {/* Feature 1 */}
              <div className="col-sm-6 m-b30">
                <label className="form-label">Feature 1</label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue=""
                  placeholder="Enter button text"
                />
              </div>

              {/* Feature 2 */}
              <div className="col-sm-6 m-b30">
                <label className="form-label">Feature 2</label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue=""
                  placeholder="Enter button text"
                />
              </div>

              {/* Feature 3 */}
              <div className="col-sm-6 m-b30">
                <label className="form-label">Feature 3</label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue=""
                  placeholder="Enter button text"
                />
              </div>

              {/* Client Name  */}
              <div className="col-sm-6 m-b30">
                <label className="form-label">Feature 3</label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue=""
                  placeholder="Enter button text"
                />
              </div>

              {/* Promo Code */}
              <div className="col-sm-6 m-b30">
                <label className="form-label">Promo Code</label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue=""
                  placeholder="Enter button text"
                />
              </div>

              {/* Button Text (text) */}
              <div className="col-sm-6 m-b30">
                <label className="form-label">Button Text</label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue=""
                  placeholder="Enter button text"
                />
              </div>

              {/* Button URL (text) */}
              <div className="col-sm-6 m-b30">
                <label className="form-label">Button URL</label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue=""
                  placeholder="Enter button URL"
                />
              </div>

              {/* Button URL Type (select) */}
              <div className="col-sm-6 m-b30">
                <label className="form-label">Button URL Type</label>
                <Select
                  options={urlTypeOptions}
                  className="custom-react-select"
                  defaultValue={urlTypeOptions[0]}
                  isSearchable={false}
                />
              </div>

              {/* Text View Text (text) */}
              <div className="col-sm-6 m-b30">
                <label className="form-label">Text View Text</label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue=""
                  placeholder="Enter text view text"
                />
              </div>

              {/* Text View URL (text) */}
              <div className="col-sm-6 m-b30">
                <label className="form-label">Text View URL</label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue=""
                  placeholder="Enter text view URL"
                />
              </div>

              {/* Text View URL Type (select) */}
              <div className="col-sm-6 m-b30">
                <label className="form-label">Text View URL Type</label>
                <Select
                  options={urlTypeOptions}
                  className="custom-react-select"
                  defaultValue={urlTypeOptions[0]}
                  isSearchable={false}
                />
              </div>

              {/* Status (select) */}
              <div className="col-sm-6 m-b30">
                <label className="form-label">Status</label>
                <Select
                  options={statusOptions}
                  className="custom-react-select"
                  defaultValue={statusOptions[0]}
                  isSearchable={false}
                />
              </div>
            </div>
          </div>
          <div className="card-footer">
            <button className="btn btn-primary">UPDATE</button>
            <Link to={"#"} className="btn-link">
              Forgot your password?
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProfile;
