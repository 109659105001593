import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import BannerOffcanvas from "../../constant/Banneroffcanvas";
import MainPagetitle from "../../layouts/MainPagetitle";

// Import avatar images
import avatar1 from "../../../images/avatar/1.jpg";
import avatar2 from "../../../images/avatar/2.jpg";
import avatar3 from "../../../images/avatar/3.jpg";
import { connect, useDispatch } from "react-redux";
import {
  createBannerAction,
  deleteBannerAction,
  getBannersAction,
} from "../../../store/actions/BannerActions";

const Banners = (props) => {
  const [tableData, setTableData] = useState([]);
  const [activePage, setActivePage] = useState(0);
  const sort = 10;
  const bannerRef = useRef(null);
  const dispatch = useDispatch();
  const [selectedBanner, setSelectedBanner] = useState(undefined);

  useEffect(() => {
    // dispatch(loadingToggleAction(true));
    dispatch(getBannersAction());
  }, []);

  const refreshBannerList = () => {
    dispatch(getBannersAction()); // Fetch the updated banner list after add/update
  };

  useEffect(() => {
    setTableData(props.bannerData);
  }, [props.bannerData]);

  useEffect(() => {
    if (selectedBanner) {
      bannerRef.current.showEmployeModal();
    }
  }, [selectedBanner]);

  const handleDelete = (index) => {
    if (window.confirm("Are you sure you want to delete this banner?")) {
      const actualIndex = startIndex + index; // Calculate the correct index

      const newData = tableData.filter((_, i) => i !== actualIndex);
      let tobeDeleted = tableData[actualIndex];
      tobeDeleted.deleted = 1;

      dispatch(deleteBannerAction(tobeDeleted, tobeDeleted._id));
      setTableData(newData);
    }
  };

  const handleEdit = (index) => {
    console.log("Edit banner at index:", index);
    const actualIndex = startIndex + index; // Calculate the correct index

    // navigate("/add");
    setSelectedBanner(tableData[actualIndex]);
  };

  const pagination = Array(Math.ceil(tableData.length / sort))
    .fill()
    .map((_, i) => i + 1);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const startIndex = activePage * sort;
  const endIndex = startIndex + sort;
  const paginatedData = tableData.slice(startIndex, endIndex);

  return (
    <>
      <MainPagetitle
        mainTitle="Banners"
        pageTitle="Banners"
        parentTitle="Home"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">Banners</h4>
                    <div className="d-flex align-items-center">
                      <Link
                        to="#"
                        className="btn btn-primary btn-sm ms-1"
                        onClick={() => {
                          setSelectedBanner(undefined);
                          setTimeout(() => {
                            bannerRef.current.showEmployeModal();
                          }, 500);
                        }}
                      >
                        + Add Banner
                      </Link>
                    </div>
                  </div>

                  <div
                    id="Banners-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table className="table ItemsCheckboxSec dataTable no-footer mb-0">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Image</th>
                          <th>Status</th>
                          <th>Date</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginatedData.map((item, index) => (
                          <tr key={index}>
                            <td>{item.title}</td>
                            <td>
                              <img
                                src={item.imageUrl}
                                alt={item.title}
                                style={{
                                  height: "200px",
                                  aspectRatio: "16/9",
                                  // objectFit: "fit",
                                }}
                              />
                            </td>
                            <td>
                              <span
                                className={`badge light border-0 ${
                                  item.status === true
                                    ? "badge-success"
                                    : "badge-danger"
                                }`}
                              >
                                {item.status === true ? "Active" : "Inactive"}
                              </span>
                            </td>
                            <td>{item.createdAt}</td>
                            <td>
                              <Link
                                to="#"
                                className="btn btn-primary btn-sm me-2"
                                onClick={() => handleEdit(index)}
                              >
                                <i className="fa-solid fa-pencil-alt" />
                              </Link>
                              <button
                                className="btn btn-danger btn-sm"
                                onClick={() => handleDelete(index)}
                              >
                                <i className="fa-solid fa-trash" />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div className="dataTables_info">
                      Showing {startIndex + 1} to{" "}
                      {tableData.length > endIndex
                        ? endIndex
                        : tableData.length}{" "}
                      of {tableData.length} entries
                    </div>
                    <div className="dataTables_paginate paging_simple_numbers">
                      <Link
                        className="paginate_button previous"
                        to="#"
                        onClick={() =>
                          activePage > 0 && handlePageChange(activePage - 1)
                        }
                      >
                        <i className="fa-solid fa-angle-left" />
                      </Link>
                      <span>
                        {pagination.map((number, i) => (
                          <Link
                            key={i}
                            to="#"
                            className={`paginate_button ${
                              activePage === i ? "current" : ""
                            }`}
                            onClick={() => handlePageChange(i)}
                          >
                            {number}
                          </Link>
                        ))}
                      </span>
                      <Link
                        className="paginate_button next"
                        to="#"
                        onClick={() =>
                          activePage < pagination.length - 1 &&
                          handlePageChange(activePage + 1)
                        }
                      >
                        <i className="fa-solid fa-angle-right" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BannerOffcanvas
        ref={bannerRef}
        Title={selectedBanner ? "Edit Banner" : "Add Banner"}
        selectedBanner={selectedBanner}
        onSuccess={refreshBannerList} // This will trigger the banner list refresh
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.banners.errorMessage,
    successMessage: state.banners.successMessage,
    bannerData: state.banners.banners,
    showLoading: state.banners.showLoading,
  };
};
export default connect(mapStateToProps)(Banners);
