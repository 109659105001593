import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Offcanvas } from "react-bootstrap";
import Select from "react-select";

// Options for the Select fields
const optionsButtonURLType = [
  { value: "External", label: "External" },
  { value: "Internal", label: "Internal" },
];

const optionsTextViewURLType = [
  { value: "External", label: "External" },
  { value: "Internal", label: "Internal" },
];

const optionsStatus = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];

const EmployeeOffcanvas = forwardRef(({ onSubmit }, ref) => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [formData, setFormData] = useState({
    Id: "",
    identifier: "",
    brandName: "",
    headline: "",
    brandImage: "",
    bannerImage: "",
    buttonText: "",
    buttonURL: "",
    buttonURLType: optionsButtonURLType[0],
    textViewText: "",
    textViewURL: "",
    textViewURLType: optionsTextViewURLType[0],
    status: optionsStatus[0],
  });

  useImperativeHandle(ref, () => ({
    showEmployeeOffcanvasModal() {
      setShowOffcanvas(true);
    },
  }));

  const handleInputChange = (e, field) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  const handleSelectChange = (selectedOption, field) => {
    setFormData({ ...formData, [field]: selectedOption });
  };

  const handleImageUpload = (e, field) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, [field]: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData); // Pass form data to the parent component
    setShowOffcanvas(false);
  };

  return (
    <Offcanvas
      show={showOffcanvas}
      onHide={() => setShowOffcanvas(false)}
      placement="end"
      className="offcanvas-end"
    >
      <div className="offcanvas-header">
        <h5 className="modal-title">Add New Task</h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => setShowOffcanvas(false)}
        ></button>
      </div>
      <div className="offcanvas-body">
        <form onSubmit={handleSubmit}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label">ID</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.Id}
                  onChange={(e) => handleInputChange(e, "Id")}
                  required
                />
              </div>

              <div className="col-md-6 mb-3">
                <label className="form-label">Identifier</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.identifier}
                  onChange={(e) => handleInputChange(e, "identifier")}
                />
              </div>

              <div className="col-md-6 mb-3">
                <label className="form-label">Brand Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.brandName}
                  onChange={(e) => handleInputChange(e, "brandName")}
                />
              </div>

              <div className="col-md-6 mb-3">
                <label className="form-label">Headline</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.headline}
                  onChange={(e) => handleInputChange(e, "headline")}
                />
              </div>

              <div className="col-md-6 mb-3">
                <label className="form-label">Button URL Type</label>
                <Select
                  options={optionsButtonURLType}
                  value={formData.buttonURLType}
                  onChange={(option) => handleSelectChange(option, "buttonURLType")}
                  className="custom-react-select"
                  isSearchable={false}
                />
              </div>

              <div className="col-md-6 mb-3">
                <label className="form-label">Status</label>
                <Select
                  options={optionsStatus}
                  value={formData.status}
                  onChange={(option) => handleSelectChange(option, "status")}
                  className="custom-react-select"
                  isSearchable={false}
                />
              </div>

              <div className="col-md-6 mb-3">
                <label className="form-label">Button Text</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.buttonText}
                  onChange={(e) => handleInputChange(e, "buttonText")}
                />
              </div>

              <div className="col-md-6 mb-3">
                <label className="form-label">TextView Text</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.textViewText}
                  onChange={(e) => handleInputChange(e, "textViewText")}
                />
              </div>

              <div className="col-md-6 mb-3">
                <label className="form-label">TextView URL Type</label>
                <Select
                  options={optionsTextViewURLType}
                  value={formData.textViewURLType}
                  onChange={(option) => handleSelectChange(option, "textViewURLType")}
                  className="custom-react-select"
                  isSearchable={false}
                />

              </div>

              <div className="col-md-6 mb-3">
                <label className="form-label">Brand Image</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => handleImageUpload(e, "brandImage")}
                />
                {formData.brandImage && (
                  <img
                    src={formData.brandImage}
                    alt="Brand"
                    className="img-thumbnail mt-2"
                    style={{ width: "100px", height: "auto" }}
                  />
                )}
              </div>

              <div className="col-md-6 mb-3">
                <label className="form-label">Banner Image</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => handleImageUpload(e, "bannerImage")}
                />
                {formData.bannerImage && (
                  <img
                    src={formData.bannerImage}
                    alt="Banner"
                    className="img-thumbnail mt-2"
                    style={{ width: "100px", height: "auto" }}
                  />
                )}
              </div>
            </div>

            <div className="mt-3">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
              <button
                type="button"
                onClick={() => setShowOffcanvas(false)}
                className="btn btn-secondary ms-3"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </Offcanvas>
  );
});

export default EmployeeOffcanvas;
