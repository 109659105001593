export const CREATE_BANNER_ACTION = "[BANNER Action] Create BANNER";
export const CONFIRMED_CREATE_BANNER_ACTION =
  "[BANNER Action] Confirmed Create BANNER";
export const GET_BANNERS = "[BANNER Action] Get BANNERs";
export const CONFIRMED_GET_BANNERS = "[BANNER Action] Confirmed Get BANNERs";
export const EDIT_BANNER_ACTION = "[BANNER Action] Edit BANNER";
export const CONFIRMED_EDIT_BANNER_ACTION =
  "[BANNER Action] Confirmed Edit BANNER";
export const CONFIRMED_DELETE_BANNER_ACTION =
  "[BANNER Action] Confirmed Delete BANNER";
