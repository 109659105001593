import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import PostsReducer from "./reducers/PostsReducer";
import { default as thunk } from "redux-thunk";
import { AuthReducer } from "./reducers/AuthReducer";
import todoReducers from "./reducers/Reducers";
import BannersReducer from "./reducers/BannersReducer";
import ServicesReducers from "./reducers/ServicesReducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action"; // Default: localStorage for web

// Middleware configuration
const middleware = [thunk]; // You can add more middlewares to this array if needed

// Redux DevTools configuration
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Combine your reducers
const rootReducer = combineReducers({
  banners: BannersReducer,
  auth: AuthReducer,
  services: ServicesReducers, // It's good practice to give the reducer a meaningful name
  // form: reduxFormReducer, // Uncomment if you're using redux-form
});

// Configuration for redux-persist
const persistConfig = {
  key: "root",
  storage, // Define storage (localStorage in this case)
  whitelist: ["auth"], // Only persist the `auth` reducer
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store with the persisted reducer
// const store = createStore(persistedReducer);
// Create the store with middleware and enhancers
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middleware))
);

// Create the persistor
const persistor = persistStore(store);

export { store, persistor };
